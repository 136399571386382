import moment from 'moment';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import api from '~/services/api';
import {
  formatCurrencyAsText,
  moneyFormat,
  transformAsCurrency,
} from '~/utils/functions';

import { CalculoMargem } from '~/utils/classes/CalculoMargem';
import {
  EntradaSaidaAgg,
  ExtratoProps,
  FormDataProps,
  GridColDefCustomProps,
  HistoricoprodutoContextDataProps,
  HistoricoProdutoContextProviderProps,
  ProdutoProps,
} from './protocols';

import { toast } from 'react-toastify';
import { produtoDefault } from './Default/produtoDefault';
import { format } from 'date-fns';
import { nanoid } from 'nanoid';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

export const HistoricoProdutoContext = createContext(
  {} as HistoricoprodutoContextDataProps,
);

export function HistoricoProdutoContextProvider({
  children,
  user,
}: HistoricoProdutoContextProviderProps): JSX.Element {
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [produto, setProduto] = useState<ProdutoProps>(produtoDefault);
  const [titleExtrato, setTitleExtrato] = useState('');
  const [isExtrato, setIsOpenExtrato] = useState<boolean>(false);
  const [extrato, setExtrato] = useState<ExtratoProps[]>([]);
  const [loadingExtrato, setLoadingExtrato] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [columnsAlteracaoPreco, setColumnsAlteracaoPreco] = useState<
    GridColDefCustomProps[]
  >([]);
  const [columnsExtratoVenda, setColumnsExtratoVenda] = useState<
    GridColDefCustomProps[]
  >([]);
  const [columnsExtratoEstoque, setColumnsExtratoEstoque] = useState<
    GridColDefCustomProps[]
  >([]);
  const [columnsAlteracaoCusto, setColumnsAlteracaoCusto] = useState<
    GridColDefCustomProps[]
  >([]);
  const [tiposCategoriaSelect, setTiposCategoriaSelect] = useState<number>(-1);
  const [entradaSaida, setEntradaSaida] = useState<EntradaSaidaAgg>({
    pdv_agg: null,
    fornecedor_agg: null,
    cliente_agg: null,
    ajustes_agg: null,
  });
  const [isLoadingOpeningModal, setIsLoadingOpeningModal] = useState(false);
  const [listaPreco, setListaPreco] = useState<ExtratoProps[]>([]);
  const [loadingListaPreco, setLoadingListaPreco] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormDataProps>({
    cod_produto: '',
    cod_loja: 0,
    dta_ini: moment()
      .subtract(15, 'days')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss[Z]'),
    dta_fim: moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
    dias_inicio: moment().subtract(15, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
    tipo_operacao_entrada: -1,
  });
  const [isFetchingTable, setIsFetchingTable] = useState<boolean>(false);
  const [callRequestDetalhado, setCallRequestDetalhado] = useState(false);
  const [limitExtrato, setLimitExtrato] = useState(0);
  const [limitPageExt, setPageLimitExt] = useState(10);
  const [pageExt, setPageExt] = useState(1);
  const [precoArredondamento, setPrecoArredondamento] = useState<any[]>([]);
  const [qtdTotalVenda, setQtdTotalVenda] = useState<any>(0);
  const [totalVenda, setTotalVenda] = useState<any>(0);
  const [callGetHistorico, setCallGetHistorico] = useState<boolean>(false);
  const [months, setMonths] = useState<string[]>([]);
  const [resetPage, setResetPages] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      if (user.precos_arredondamentos) {
        setPrecoArredondamento(user.precos_arredondamentos);
      }
    }
  }, [user]);

  const getMonthNames = useCallback(() => {
    const monthNames = [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ];
    const currentMonth = new Date().getMonth();

    const last12Months = Array.from({ length: 12 }, (_, i) => {
      const monthIndex = (currentMonth - i + 12) % 12;
      return monthNames[monthIndex];
    }).reverse();

    return last12Months;
  }, []);

  const handleGet = useCallback(
    (title = titleExtrato) => {
      switch (title) {
        case 'venda':
          if (
            formData.cod_produto &&
            formData.cod_loja &&
            formData.dta_ini &&
            formData.dta_fim
          ) {
            getExtratoVenda(
              formData.cod_produto,
              formData.cod_loja,
              formData.dta_ini,
              formData.dta_fim,
            );
            setLoadingExtrato(false);
          }
          break;
        case 'entradaSaida':
          if (formData.cod_produto && formData.cod_loja && formData.dta_ini) {
            getEntradaSaida(
              formData.cod_produto,
              formData.cod_loja,
              moment.utc(formData.dta_ini).format('YYYY-MM-DD'),
              moment.utc(formData.dta_fim).format('YYYY-MM-DD'),
              formData.tipo_operacao_entrada,
            );

            let diasInicio = 0;

            if (tiposCategoriaSelect === -1) diasInicio = 15;
            else diasInicio = tiposCategoriaSelect;

            getEntradaSaidaDetalhado(
              String(formData.cod_produto),
              formData.cod_loja,
              diasInicio,
              true,
            );
            setLoadingExtrato(false);
          }
          break;
        case 'tabelaPreco':
          if (
            formData.cod_produto &&
            formData.cod_loja &&
            formData.dta_ini &&
            formData.dta_fim
          ) {
            let diasInicio = 0;

            if (tiposCategoriaSelect === -1) diasInicio = 15;
            else diasInicio = tiposCategoriaSelect;
            getAlteracaoPreco(
              formData.cod_produto,
              formData.cod_loja,
              diasInicio,
              limitPageExt,
              pageExt,
            );
          }

          break;
        case 'tabelaCusto':
          if (
            formData.cod_produto &&
            formData.cod_loja &&
            formData.dta_ini &&
            formData.dta_fim
          ) {
            let diasInicio = 0;

            if (tiposCategoriaSelect === -1) diasInicio = 15;
            else diasInicio = tiposCategoriaSelect;
            getAlteracaoCusto(
              formData.cod_produto,
              formData.cod_loja,
              diasInicio,
              limitPageExt,
              pageExt,
            );
          }

          break;
        default:
          setLoadingExtrato(false);
          break;
      }
    },
    [titleExtrato, formData, tiposCategoriaSelect, limitPageExt, pageExt],
  );

  useEffect(() => {
    if (callGetHistorico) {
      handleGet();
      setCallGetHistorico(false);
    }
  }, [formData.dta_ini, formData.tipo_operacao_entrada]);

  const handleFormData = useCallback((value: FormDataProps) => {
    setFormData(value);
  }, []);

  const handleTitleExtrato = useCallback((value: string) => {
    setTitleExtrato(value);
  }, []);
  const handleOpenModalHistorico = useCallback((value: boolean) => {
    setIsOpenModal(value);
  }, []);

  const handleTipoCategoria = useCallback((value: number) => {
    setTiposCategoriaSelect(value);
  }, []);

  const handleLoadingExtrato = useCallback((value: boolean) => {
    setLoadingExtrato(value);
  }, []);

  const handleIsOpenExtrato = useCallback((value: boolean) => {
    setIsOpenExtrato(value);
    if (!value) {
      setColumns([]);
    }
  }, []);

  const handleLimitExt = useCallback((l: number) => {
    setPageLimitExt(l);
  }, []);

  const handlePagetExt = useCallback((p: number) => {
    setPageExt(p);
  }, []);

  const handleCardClick = useCallback(
    (value: string) => {
      setLoadingExtrato(true);
      handleGet(value);
    },
    [handleGet],
  );

  const onCallRequestDetalhado = useCallback((value: boolean) => {
    setCallRequestDetalhado(value);
  }, []);

  const onOpeningModal = useCallback((val: boolean) => {
    setIsLoadingOpeningModal(val);
  }, []);

  const getHistoricoProduto = async (
    cod_produto: number | string,
    cod_loja: number | string,
  ) => {
    setLoading(true);
    try {
      setLoadingListaPreco(true);
      setListaPreco([]);
      setMonths(getMonthNames());
      const { data } = await api.get('/historico-produto', {
        params: {
          cod_produto,
          cod_loja,
        },
      });

      if (data.success && data.data.length > 0) {
        const products = {
          ...data.data[0],
          dta_ult_mov_venda: data.data[0].dta_ult_mov_venda
            ? format(
                createDateWithoutTimezone(data.data[0].dta_ult_mov_venda),
                'dd/MM/yy',
              )
            : null,
          dta_ult_alt_custo_rep: data.data[0].dta_ult_alt_custo_rep
            ? format(
                createDateWithoutTimezone(data.data[0].dta_ult_alt_custo_rep),
                'dd/MM/yy',
              )
            : null,
        };
        setProduto(products);
        if (data.data[0].lista_precos) {
          if (data.data[0].lista_precos.length > 0) {
            const temp: any = [];
            data.data[0].lista_precos.map(async (lista_preco: any) => {
              const lista_precos = await calculaMargem(lista_preco, products);
              temp.push(lista_precos);
            });

            // Retirando espaço desnecessario no top do modal
            const modalHeader = document.getElementById('modalHeader');
            const modal = modalHeader?.parentElement;
            if (modal) {
              modal.style.paddingTop = '0px';
              modal.style.paddingBottom = '0px';
            }

            setTimeout(() => {
              setListaPreco(temp);
              setLoadingListaPreco(false);
            }, 100);
          }
        }
        setIsOpenModal(true);
        setLoadingListaPreco(false);
      } else {
        setIsOpenModal(false);
        onOpeningModal(false);
        setLoadingListaPreco(false);
        toast.warning(
          'Não foi possível buscar o histórico desse item. Por favor contate nosso suporte!',
        );
      }
    } catch (error) {
      setIsLoadingOpeningModal(false);
    } finally {
      setLoading(false);
    }
  };

  const calculaMargem = async (data: any, produtoVal: any) => {
    setLoadingListaPreco(true);

    if (data) {
      const { cod_preco, val_margem_ref, val_preco, val_preco_anterior } = data;

      const calcSugVda = await CalculoMargem.getValSugestaoVenda(
        produtoVal.val_custo_rep,
        produtoVal.val_custo_sem_imposto,
        val_margem_ref,
        produtoVal.per_desp_op,
        produtoVal.per_icms_saida,
        produtoVal.tipo_margem,
        cod_preco,
        produtoVal.per_fcp,
        produtoVal.per_pis_lj,
        produtoVal.per_cofins_lj,
        false,
        precoArredondamento,
      );

      const calcMargVda = await CalculoMargem.getValMargemVenda(
        produtoVal.tipo_margem,
        produtoVal.val_custo_rep,
        produtoVal.val_custo_sem_imposto,
        transformAsCurrency(val_preco),
        produtoVal.val_imposto_debito,
        produtoVal.per_desp_op,
      );

      const lista_precos = data;
      val_margem_ref;
      lista_precos.val_preco_anterior = moneyFormat(val_preco_anterior);
      lista_precos.val_margem_ref = moneyFormat(val_margem_ref);
      lista_precos.val_preco = moneyFormat(val_preco);

      lista_precos.calcSugVda = formatCurrencyAsText(Number(calcSugVda));
      lista_precos.calcMargVda = formatCurrencyAsText(Number(calcMargVda));
      lista_precos.variacao = formatCurrencyAsText(
        calcMargVda - val_margem_ref,
      );

      return lista_precos;
    }
    setLoadingListaPreco(false);
    setListaPreco([]);
  };

  const getExtratoVenda = async (
    cod_produto: number | string,
    cod_loja: number,
    dta_ini: string,
    dta_fim: string,
  ) => {
    setExtrato([]);

    setColumnsExtratoVenda([
      {
        field: 'dta_cupom',
        headerName: 'Período',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        width: '33%',
      },
      {
        field: 'qtd_total',
        headerName: 'Qtd Total',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        format: 'qtd',
        width: '33%',
      },
      {
        field: 'valor_total',
        headerName: 'R$ Total',
        editable: false,
        headerAlign: 'right',
        align: 'right',
        hideRightSeparator: true,
        width: '34%',
      },
    ]);
    setIsFetchingTable(true);

    try {
      const { data } = await api.get('/historico-produto/vendas', {
        params: {
          cod_produto,
          cod_loja,
          dta_ini,
          dta_fim,
        },
      });

      let qtd_total_totalizador = 0;
      let total_vendas_totalizador = 0;

      if (data.success) {
        const extract = data.data.map((ex: any) => {
          total_vendas_totalizador += transformAsCurrency(ex.valor_total);
          qtd_total_totalizador += ex.qtd_total;
          ex.id = nanoid();
          return ex;
        });

        setQtdTotalVenda(qtd_total_totalizador);
        setTotalVenda(total_vendas_totalizador);

        setExtrato(extract);
        setLoadingExtrato(false);
        setIsFetchingTable(false);
      } else {
        setExtrato([]);
        setLoadingExtrato(false);
        setIsFetchingTable(false);
      }
    } finally {
      setLoadingExtrato(false);
      setIsFetchingTable(false);
    }
  };

  const getAlteracaoPreco = async (
    cod_produto: number | string,
    cod_loja: number,
    dta_ini: number,
    limit: number,
    page: number,
  ) => {
    setExtrato([]);
    setColumnsAlteracaoPreco([
      {
        field: 'des_preco',
        headerName: 'Preço',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        width: '15%',
      },
      {
        field: 'val_anterior',
        headerName: 'Anterior',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        width: '20%',
      },
      {
        field: 'val_posterior',
        headerName: 'Posterior',
        editable: false,
        headerAlign: 'right',
        align: 'right',
        width: '15%',
      },
      {
        field: 'dta_alteracao',
        headerName: 'Data',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        width: '25%',
      },
      {
        field: 'des_usuario',
        headerName: 'Usuário',
        editable: false,
        headerAlign: 'left',
        align: 'left',
        width: '25%',
        hideRightSeparator: true,
      },
    ]);
    setIsFetchingTable(true);

    try {
      const { data } = await api.post('/historico-produto/historico-preco', {
        cod_produto: cod_produto.toString().padStart(6, '0'),
        cod_loja,
        dta_ini,
        limit,
        page,
      });

      if (data) {
        if (data.success) {
          const historicoProduto = data.data.map((ex: any) => ({
            ...ex,
            val_posterior: formatCurrencyAsText(ex.val_posterior),
            val_anterior: formatCurrencyAsText(ex.val_anterior),
            id: nanoid(),
          }));

          setIsFetchingTable(false);
          setExtrato(historicoProduto);
          setLoadingExtrato(false);
          setLimitExtrato(+data.count);
        } else {
          setIsFetchingTable(false);
          setExtrato([]);
          setLoadingExtrato(false);
        }
      }
    } finally {
      setIsFetchingTable(false);
      setLoadingExtrato(false);
    }
  };

  const getAlteracaoCusto = async (
    cod_produto: number | string,
    cod_loja: number,
    dta_ini: number,
    limit: number,
    page: number,
  ) => {
    setExtrato([]);
    setColumnsAlteracaoCusto([
      {
        field: 'val_anterior',
        headerName: 'Anterior',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        width: '33%',
      },
      {
        field: 'val_posterior',
        headerName: 'Posterior',
        editable: false,
        headerAlign: 'center',
        align: 'right',
        width: '28%',
      },
      {
        field: 'dta_alteracao',
        headerName: 'Data',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        width: '39%',
        hideRightSeparator: true,
      },
    ]);
    setIsFetchingTable(true);

    try {
      const { data } = await api.post('/historico-produto/historico-custo', {
        cod_produto: cod_produto.toString().padStart(6, '0'),
        cod_loja,
        dta_ini,
        limit,
        page,
      });
      if (data) {
        if (data.success) {
          const historicoProduto = data.data.map((ex: any) => ({
            ...ex,
            val_posterior: formatCurrencyAsText(ex.val_posterior),
            val_anterior: formatCurrencyAsText(ex.val_anterior),
            id: nanoid(),
          }));

          setIsFetchingTable(false);
          setExtrato(historicoProduto);
          setLoadingExtrato(false);
          setLimitExtrato(+data.count);
        } else {
          setIsFetchingTable(false);
          setExtrato([]);
          setLoadingExtrato(false);
        }
      }
    } finally {
      setIsFetchingTable(false);
      setLoadingExtrato(false);
    }
  };

  const getEntradaSaida = async (
    cod_produto: number | string,
    cod_loja: number,
    dta_ini: string,
    dta_fim: string,
    tipo_operacao_entrada: any,
  ) => {
    setColumns([
      { field: 'id', hide: true },
      {
        id: 'cfop',
        left: 0,
        label: 'CFOP',
        width: '400px',
        minWidth: '80px',
        align: 'center',
        alignColumn: 'center',
      },
      {
        id: 'tipo_nf',
        left: 100,
        label: 'Tipo NF',
        width: '100px',
        minWidth: '90px',
        align: 'center',
        alignColumn: 'center',
      },
      {
        id: 'flg_bonificado',
        width: '100px',
        minWidth: '100px',
        label: 'Bonif.?',
        align: 'center',
        alignColumn: 'center',
      },
      {
        id: 'dta_entrada',
        width: '100px',
        minWidth: '120px',
        label: 'Data Docto',
        align: 'center',
        alignColumn: 'center',
      },
      {
        id: 'qtd_entrada',
        width: '100px',
        minWidth: '100px',
        label: 'Qtd',
        align: 'center',
        alignColumn: 'center',
      },
      {
        id: 'num_nf',
        width: '100px',
        minWidth: '100px',
        label: 'Núm. NF',
        align: 'center',
        alignColumn: 'center',
      },
      {
        id: 'des_parceiro',
        width: '100px',
        minWidth: '120px',
        label: 'Parceiro',
        align: 'left',
        alignColumn: 'left',
      },
      {
        id: 'dta_cadastro',
        width: '100px',
        minWidth: '200px',
        label: 'Data de Inclusão',
        align: 'center',
        alignColumn: 'center',
      },
    ]);

    setIsFetchingTable(true);

    try {
      const { data } = await api.get('/historico-produto/entradas-saida', {
        params: {
          dta_ini,
          dta_fim,
          tipo_operacao_entrada,
          cod_produto,
          cod_loja,
        },
      });
      if (data.success) {
        const entradaSaidaMap = data.data.map((ex: any) => ({
          ...ex,
          id: nanoid(),
        }));

        setExtrato(entradaSaidaMap);
        setLoadingExtrato(false);
      } else {
        setExtrato([]);
        setLoadingExtrato(false);
      }
      setIsFetchingTable(false);
    } finally {
      setLoadingExtrato(false);
      setIsFetchingTable(false);
    }
  };

  const adicionarIdParaArrays = (objeto: any, chavesArrays: string[]) => {
    const chaves = Object.keys(objeto);

    chaves.forEach((chave) => {
      if (Array.isArray(objeto[chave]) && chavesArrays.includes(chave)) {
        objeto[chave].forEach((item: any) => {
          item.id = nanoid();

          Object.keys(item).forEach((subChave) => {
            if (subChave.startsWith('dta_')) {
              if (subChave === 'dta_cadastro') {
                item[subChave] = format(
                  new Date(item[subChave]),
                  'dd/MM/yyyy HH:mm:ss',
                );
              } else if (!item[subChave].includes('00:00')) {
                item[subChave] = format(
                  new Date(`${item[subChave]} 00:00:00`),
                  'dd/MM/yyyy',
                );
              } else {
                item[subChave] = format(new Date(item[subChave]), 'dd/MM/yyyy');
              }
            } else if (subChave.startsWith('flg_')) {
              item[subChave] = item[subChave] ? 'Sim' : 'Não';
            } else if (subChave.startsWith('qtd_')) {
              item[subChave] = item[subChave].toString().replace(/\./g, ',');
            }
          });
        });
      }
    });

    return objeto;
  };

  const getEntradaSaidaDetalhado = async (
    cod_produto: string,
    cod_loja: number,
    dias_inicio: number,
    hasCallRequestDetalhado = false,
  ) => {
    if (!hasCallRequestDetalhado) return;

    setIsFetchingTable(true);

    const { data } = await api.get(
      '/historico-produto/entradas-saida-detalhado',
      {
        params: { cod_produto, cod_loja, dias_inicio },
      },
    );

    if (data.success && data.data.length > 0) {
      const result = data.data[0];
      const entradaSaidaArr = adicionarIdParaArrays(result, [
        'pdv_agg',
        'fornecedor_agg',
        'cliente_agg',
        'ajustes_agg',
      ]);

      setEntradaSaida(entradaSaidaArr);
      setIsFetchingTable(false);
    }
  };

  const getExtratoEstoque = useCallback(
    async (
      cod_produto: number | string,
      cod_loja: number,
      dta_ini: string,
      dta_fim: string,
      limit: number,
      page: number,
    ) => {
      setExtrato([]);

      setColumnsExtratoEstoque([
        {
          field: 'r_num_docto',
          headerName: 'Docto',
          editable: false,
          headerAlign: 'left',
          align: 'left',
          width: '230px',
        },

        {
          field: 'r_dta_movimento',
          headerName: 'Dta Lanç.',
          editable: false,
          headerAlign: 'center',
          align: 'center',
          width: '100px',
        },
        {
          field: 'r_num_operacao',
          headerName: 'Operação',
          editable: false,
          headerAlign: 'left',
          align: 'left',
          width: '100px',
        },
        {
          field: 'view_r_tipo_operacao',
          headerName: 'Tipo Mov',
          editable: false,
          headerAlign: 'left',
          align: 'left',
          width: '100px',
        },
        {
          field: 'r_qtd_est_ant',
          headerName: 'Estq. Ant',
          editable: false,
          headerAlign: 'right',
          align: 'right',
          width: '100px',
        },
        {
          field: 'r_qtd_ajuste',
          headerName: 'Qtd Ajuste',
          editable: false,
          headerAlign: 'right',
          align: 'right',
          width: '100px',
        },
        {
          field: 'r_qtd_est_post',
          headerName: 'Estq. Post',
          editable: false,
          headerAlign: 'right',
          align: 'right',
          width: '100px',
        },
        {
          field: 'r_dta_processamento',
          headerName: 'Data Processado',
          editable: false,
          headerAlign: 'right',
          align: 'right',
          width: '150px',
          hideRightSeparator: true,
        },
      ]);

      setIsFetchingTable(true);

      try {
        const { data } = await api.post('/historico-produto/estoque', {
          cod_produto: cod_produto.toString().padStart(6, '0'),
          cod_loja,
          dta_ini,
          dta_fim,
          limit,
          page,
        });

        if (data.success) {
          const opts = data.data.map((i: any) => {
            return {
              ...i,
              id: nanoid(),
            };
          });

          setExtrato(opts);
          setLoadingExtrato(false);
          setIsFetchingTable(false);
          setLimitExtrato(+data.count);
        } else {
          setExtrato([]);
          setIsFetchingTable(false);
          setLoadingExtrato(false);
        }
      } finally {
        setIsFetchingTable(false);
        setLoadingExtrato(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (titleExtrato === 'estoque') {
      if (
        formData.cod_produto &&
        formData.cod_loja &&
        formData.dta_ini &&
        formData.dta_fim
      ) {
        getExtratoEstoque(
          formData.cod_produto,
          formData.cod_loja,
          formData.dta_ini,
          formData.dta_fim,
          limitPageExt,
          pageExt,
        );
        setLoadingExtrato(false);
      }
    }
  }, [formData, limitPageExt, pageExt, titleExtrato]);
  useEffect(() => {
    if (titleExtrato === 'tabelaPreco') {
      if (
        formData.cod_produto &&
        formData.cod_loja &&
        formData.dta_ini &&
        formData.dta_fim
      ) {
        let diasInicio = 0;

        if (tiposCategoriaSelect === -1) diasInicio = 15;
        else diasInicio = tiposCategoriaSelect;
        getAlteracaoPreco(
          formData.cod_produto,
          formData.cod_loja,
          diasInicio,
          limitPageExt,
          pageExt,
        );
        setLoadingExtrato(false);
      }
    }

    if (titleExtrato === 'tabelaCusto') {
      if (
        formData.cod_produto &&
        formData.cod_loja &&
        formData.dta_ini &&
        formData.dta_fim
      ) {
        let diasInicio = 0;

        if (tiposCategoriaSelect === -1) diasInicio = 15;
        else diasInicio = tiposCategoriaSelect;
        getAlteracaoCusto(
          formData.cod_produto,
          formData.cod_loja,
          diasInicio,
          limitPageExt,
          pageExt,
        );
      }
    }
  }, [limitPageExt, pageExt]);

  return (
    <HistoricoProdutoContext.Provider
      value={{
        isOpenModal,
        setIsOpenModal,
        getHistoricoProduto,
        produto,
        handleIsOpenExtrato,
        isExtrato,
        handleCardClick,
        handleFormData,
        formData,
        extrato,
        columns,
        loadingExtrato,
        handleTitleExtrato,
        handleLoadingExtrato,
        handleTipoCategoria,
        tiposCategoriaSelect,
        listaPreco,
        loadingListaPreco,
        handleOpenModalHistorico,
        entradaSaida,
        onOpeningModal,
        isLoadingOpeningModal,
        isFetchingTable,
        onCallRequestDetalhado,
        limitExtrato,
        handleLimitExt,
        handlePagetExt,
        titleExtrato,
        columnsAlteracaoPreco,
        columnsExtratoVenda,
        columnsExtratoEstoque,
        columnsAlteracaoCusto,

        qtdTotalVenda,
        totalVenda,
        setLimitExtrato,
        setEntradaSaida,
        setCallGetHistorico,
        setCallRequestDetalhado,

        months,
        loading,
        resetPage,
        setResetPages,
      }}
    >
      {children}
    </HistoricoProdutoContext.Provider>
  );
}

export const useHistoricoProduto = (): HistoricoprodutoContextDataProps => {
  return useContext(HistoricoProdutoContext);
};
